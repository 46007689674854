export type FieldControlType =
    | 'Dmn'
    | 'String'
    | 'FormLayout'
    | 'Email'
    | 'Url'
    | 'Secret'
    | 'Double'
    | 'Json'
    | 'Boolean'
    | 'Integer'
    | 'Option'
    | 'Yaml'
    | 'Xml'
    | 'Flags'
    | 'Process';
export interface IFormControl {
    code: string;
    isRequired: boolean;
    name: string;
    type: FieldControlType;
    maxLength?: number;
    options?: Option[];
    schema?: { [key: string | number]: any };
    systemSchema?: Record<string | number, any>[];
}

export enum EnumFormType {
    Simple = 'Simple',
    Form = 'Form',
}

export interface IMetaData {
    type: EnumFormType;
    code: string;
    category: string;
    columns: TableColumn[];
    fields?: IFormControl[];
    keyFields?: IFormControl[];
    singularName: string;
    pluralName: string;
    endpoint: string;
    icon: string;
    order: number;
    value?: any;
    uniqueIdentifiers: string[];
    operations?: EnumMetaOperation[];
    actions?: ListItemAction[];
    wiki?: string;
}

export interface ListItemAction {
    code: string;
    icon: string;
    method: EnumHttpMethod;
    name: string;
    requiresConfirmation?: boolean;
    url?: string;
}

export enum EnumHttpMethod {
    Delete = 'delete',
    Get = 'get',
    Post = 'post',
    Put = 'put',
    Patch = 'patch',
}

export interface TableColumn {
    code: string;
    name: string;
    order: number;
}

export interface Option {
    code: string;
    name: string;
}

export enum EnumMetaOperation {
    CanAdd = 'CanAdd',
    CanDelete = 'CanDelete',
    CanPromote = 'CanPromote',
    CanReset = 'CanReset',
    FilterOnLocal = 'FilterOnLocal',
    FilterOnDisabled = 'FilterOnDisabled',
}
